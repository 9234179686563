import { useTicketStore } from "../../Stores/Ticket";
import "./BotActionInputButtons.scss";

function BotActionInputButtons({ action }: { action: any }) {
  const ticketStore = useTicketStore();

  if (!action || !action.buttons) {
    return null;
  }

  return (
    <div className="bot-buttons">
      {action.buttons.map((button: any, index: number) => {
        return (
          <button
            key={index}
            className="bot-button"
            onClick={() => {
              ticketStore.answerBotAction(
                {
                  selected: index,
                  message: button.text,
                },
                []
              );
            }}
          >
            {button.text}
          </button>
        );
      })}
    </div>
  );
}

export default BotActionInputButtons;
