import { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import { useTicketStore } from "../../Stores/Ticket";
import "./BotActionInputTextArea.scss";

function BotActionInputTextArea({ action }: { action: any }) {
  const attribute = action?.attribute;
  const ticketStore = useTicketStore();
  const [text, setText] = useState("");
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const validInput = validateInput();
    setValid(validInput);
  }, [text]);

  if (!action || !attribute) {
    return null;
  }

  const validateInput = () => {
    // Check if the input is required.
    if (attribute.required) {
      if (!text || text.length === 0) {
        setValid(false);
        return false;
      }
    }

    return true;
  };

  const sendInput = () => {
    const validInput = validateInput();
    setValid(validInput);
    if (!validInput) {
      return;
    }

    ticketStore.answerBotAction(
      {
        text,
        message: text,
      },
      []
    );
  };

  return (
    <div className="bot-action-textarea-input">
      <div className="bot-action-textarea-input-label">{action.title}</div>
      <div className="bot-action-textarea-input-innercontainer">
        <textarea
          placeholder={attribute.placeholder ?? ""}
          value={attribute.value}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <button
          className={`${!valid && "button--disabled"}`}
          onClick={() => {
            sendInput();
          }}
          type="button"
        >
          <ArrowRight />
        </button>
      </div>
    </div>
  );
}

export default BotActionInputTextArea;
