import { useEffect } from "react";
import { ReactComponent as Chat } from "../../Assets/message-solid.svg";
import { ReactComponent as SendLogo } from "../../Assets/paper-plane-top-solid.svg";
import ConversationItem from "../../Components/ConversationItem/ConversationItem";
import FormLoading from "../../Components/FormLoading/FormLoading";
import { useConfigStore } from "../../Stores/Config";
import { useTicketStore } from "../../Stores/Ticket";
import ConversationLoading from "../ConversationLoading/ConversationLoading";
import "./Conversations.scss";

const ticketFilters = [
  {
    label: "Open",
    value: "OPEN",
  },
  {
    label: "Closed",
    value: "DONE",
  },
];

function Conversations() {
  const previousFeedbackItems = useTicketStore(
    (state) => state.previousTickets
  );
  const configStore = useConfigStore();
  const ticketStore = useTicketStore();
  const ticketFilter = ticketStore.ticketFilter;

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && previousFeedbackItems.length > 0) {
      ticketStore.loadMoreConversations();
    }
  };

  useEffect(() => {
    ticketStore.setCanLoadMore(true);
    ticketStore.loadMoreConversations({ preventLoading: true, skip: 0 });
  }, []);

  const renderFeedbackItems = () => {
    if (ticketStore.loadingTickets && previousFeedbackItems.length === 0) {
      return <ConversationLoading />;
    }

    if (!previousFeedbackItems || previousFeedbackItems.length === 0) {
      return (
        <div className="conversations-no-conversations">
          <Chat className="no-messages-yet" />
          <span className="title">
            {configStore.config.allConversationsMenuLabel}
          </span>
          <span className="description">
            {configStore.translateText("noMessages")}
          </span>
          {configStore.config.showMsgBtnOnConvTab !== false && (
            <div
              className="action-button form-send-button"
              onClick={() => {
                ticketStore.createNewConversation({});
              }}
            >
              {configStore.translateText("sendMessage")}
              <SendLogo />
            </div>
          )}
        </div>
      );
    }

    return (
      <>
        <div>
          {previousFeedbackItems.map((ticket, index) => {
            if (ticket?.noHumanInteraction) {
              return null;
            }

            return (
              <ConversationItem ticket={ticket} index={index} key={ticket.id} />
            );
          })}
          <div className="conversations-load-more">
            {ticketStore?.loadingTickets && <FormLoading />}
          </div>
        </div>
        {configStore.config.showMsgBtnOnConvTab !== false && (
          <div className="action-overlay">
            <div
              className="action-button form-send-button"
              onClick={() => {
                ticketStore.createNewConversation({});
              }}
            >
              {configStore.translateText("sendMessage")}
              <SendLogo />
            </div>
          </div>
        )}
      </>
    );
  };

  const renderFilters = () => {
    if (configStore.config.showConvFilters !== true) {
      return <></>;
    }

    return (
      <div className="conversations-filters">
        {ticketFilters.map((filter) => {
          const isActive = ticketFilter.status === filter.value;
          return (
            <div
              key={filter.value}
              className={`conversations-filter ${
                isActive ? "conversations-filter--active" : ""
              }`}
              onClick={() => {
                ticketStore.setTicketFilter({ status: filter.value });
              }}
            >
              {filter.label}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`conversations ${
        configStore.config.showConvFilters === true && "conversations--filters"
      }`}
      onScroll={handleScroll}
    >
      {renderFilters()}
      {renderFeedbackItems()}
    </div>
  );
}

export default Conversations;
