import { ReactComponent as GleapLogo } from "../../Assets/PoweredByGleapLogo.svg";
import { ReactComponent as Bolt } from "../../Assets/bolt-solid.svg";
import Communicator from "../../Helper/Communicator";
import { useConfigStore } from "../../Stores/Config";
import { useSessionStore } from "../../Stores/Session";
import "./WidgetPoweredBy.scss";

function WidgetPoweredBy() {
  const config = useConfigStore((state) => state.config);
  const sessionStore = useSessionStore((state) => state);
  const showGleapLogo =
    typeof config.hideBranding === "undefined" || !config.hideBranding;

  return (
    <div
      className={`powered-by ${!showGleapLogo && "powered-by--hidden"}`}
      onClick={() => {
        if (showGleapLogo) {
          Communicator.openURL(
            "https://www.gleap.io?utm_source=widget&utm_medium=referral&utm_content=" +
              sessionStore.apiUrl
          );
        }
      }}
    >
      {showGleapLogo && (
        <div className="powered-by--inner" dir="ltr">
          <Bolt className="bolt" />
          <span>Powered by</span>
          <GleapLogo className="logo" />
        </div>
      )}
    </div>
  );
}

export default WidgetPoweredBy;
