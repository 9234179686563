import { create } from 'zustand';
import { useRouterStore } from "./Router";
import { useSessionStore } from "./Session";
import { useConfigStore } from "./Config";
import { WebSocketEventData } from '../Models/WebSocketEventData';

export type ChecklistStep = {
  title: string,
  description: any,
  allowMarkDone: boolean,
  duration: number,
  action: string,
  actionTitle: string,
  botId?: string,
  actionBody?: any,
  actionOpenInNewTab?: boolean,
  formId?: string,
  surveyFormat?: string,
  articleId?: string,
};

export type Checklist = {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  step: number;
  status: string;
  outbound: {
    subject: string;
    message: any;
    sender: {
      firstName?: string;
      lastName?: string;
      id?: string;
      profileImageUrl?: string;
    };
    config: {
      steps: ChecklistStep[];
      successTitle?: string;
      successMessage?: string;
      confetti?: boolean;
    }
  }
};

interface ChecklistState {
  currentChecklist?: Checklist;
  checklists: Checklist[];
  loadingChecklist: boolean;
  loadChecklists: () => void;
  loadChecklist: (id: string) => void;
  startChecklist: (outboundId: string) => void;
  handleWebSocketEvent: (webSocketEventData: WebSocketEventData) => void;
  updateChecklist: (id: string, data: {
    step: number,
    status?: string,
  }) => void;
}

export const useChecklistStore = create<ChecklistState>()((set, get) => ({
  currentChecklist: undefined,
  checklists: [],
  loadingChecklist: false,
  handleWebSocketEvent: (webSocketEventData: WebSocketEventData) => {
    if (webSocketEventData.actionID && webSocketEventData.actionData?.step) {
      const step = webSocketEventData.actionData?.step;
      const status = webSocketEventData.actionData?.status;

      const currentChecklist = get().currentChecklist;
      const checklists = get().checklists;

      // Update current checklist.
      if (currentChecklist?.id === webSocketEventData.actionID) {
        const updatedCurrentChecklist = {
          ...currentChecklist,
          step,
          status,
        };
        set({
          currentChecklist: updatedCurrentChecklist,
        });

        // Update checklists.
        const updatedChecklists = checklists.map((checklist) => {
          if (checklist.id === webSocketEventData.actionID) {
            return {
              ...checklist,
              step,
              status,
            };
          }
          return checklist;
        }).filter((checklist) => checklist?.status == "active");

        set({
          checklists: updatedChecklists,
        });
      }
    }
  },
  startChecklist: (outboundId: string) => {
    if (!outboundId) {
      useRouterStore.getState().setPage("");
      return;
    }

    const sessionStore = useSessionStore.getState();
    set({
      currentChecklist: undefined,
    });

    const gleapId = sessionStore?.session?.gleapId ?? "";
    const gleapHash = sessionStore?.session?.gleapHash ?? "";

    var xhr = new XMLHttpRequest();
    xhr.open("POST", sessionStore.apiUrl + "/outbound/checklists?gleapId=" + gleapId + "&gleapHash=" + gleapHash + "&lang=" + useConfigStore.getState().getCurrentLanguage());
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
      xhr.setRequestHeader("Gleap-Hash", sessionStore.session.gleapHash ?? "");
    }
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data && data.id) {
            // Open checklist.
            useRouterStore.getState().setPage("checklistdetails", {
              id: data.id,
            });

            // Reload checklists.
            get().loadChecklists();
          } else {
            useRouterStore.getState().setPage("");
          }
        } catch (exp) {
          useRouterStore.getState().setPage("");
        }
      }
    };

    xhr.send(JSON.stringify({
      outboundId,
    }));
  },
  updateChecklist: (id: string, data: {
    step: number,
    status?: string,
  }) => {
    const sessionStore = useSessionStore.getState();
    const gleapId = sessionStore?.session?.gleapId ?? "";
    const gleapHash = sessionStore?.session?.gleapHash ?? "";

    const updatedChecklist = {
      ...get().currentChecklist,
      ...data,
    };

    var currentChecklists: any = get().checklists;
    const index = currentChecklists.findIndex((c: any) => c.id === id);
    if (index > -1) {
      if (data.status === "done") {
        currentChecklists.splice(index, 1);
      } else {
        currentChecklists[index] = updatedChecklist;
      }

      set({
        checklists: currentChecklists,
        currentChecklist: (updatedChecklist as any),
      });
    } else {
      set({
        currentChecklist: (updatedChecklist as any)
      });
    }

    var xhr = new XMLHttpRequest();
    xhr.open("PUT", sessionStore.apiUrl + "/outbound/checklists/" + id + "/increment?gleapId=" + gleapId + "&gleapHash=" + gleapHash + "&lang=" + useConfigStore.getState().getCurrentLanguage());
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
      xhr.setRequestHeader("Gleap-Hash", sessionStore.session.gleapHash ?? "");
    }
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {

      }
    };
    xhr.send();
  },
  loadChecklist: (id: string) => {
    const sessionStore = useSessionStore.getState();
    set({
      currentChecklist: undefined,
    });

    const gleapId = sessionStore?.session?.gleapId ?? "";
    const gleapHash = sessionStore?.session?.gleapHash ?? "";

    var xhr = new XMLHttpRequest();
    xhr.open("GET", sessionStore.apiUrl + "/outbound/checklists/" + id + "?gleapId=" + gleapId + "&gleapHash=" + gleapHash + "&lang=" + useConfigStore.getState().getCurrentLanguage());
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", sessionStore.session.gleapId ?? "");
      xhr.setRequestHeader("Gleap-Hash", sessionStore.session.gleapHash ?? "");
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data) {
            set({
              currentChecklist: data,
            });
          } else {
            useRouterStore.getState().setPage("");
          }
        } catch (exp) {
          useRouterStore.getState().setPage("");
        }
      }
    };

    xhr.send();
  },
  loadChecklists() {
    const sessionStore = useSessionStore.getState();

    set({
      checklists: [],
      loadingChecklist: true,
    });

    const gleapId = sessionStore?.session?.gleapId ?? "";
    const gleapHash = sessionStore?.session?.gleapHash ?? "";

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      sessionStore.apiUrl + "/outbound/checklists?gleapId=" + gleapId + "&gleapHash=" + gleapHash + "&lang=" + useConfigStore.getState().getCurrentLanguage()
    );
    if (sessionStore.session) {
      xhr.setRequestHeader("Api-Token", sessionStore.sdkKey);
      xhr.setRequestHeader("Gleap-Id", gleapId);
      xhr.setRequestHeader("Gleap-Hash", gleapHash);
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data) {
            set({
              checklists: data,
              loadingChecklist: false,
            });
          } else {
            set({
              loadingChecklist: false,
            });
          }
        } catch (exp) { }
      }
    };

    xhr.send();
  },
}));
