import { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import { validateEmail } from "../../Stores/Form";
import { useTicketStore } from "../../Stores/Ticket";
import "./BotActionInputText.scss";

function BotActionInputText({ action }: { action: any }) {
  const attribute = action?.attribute;
  const ticketStore = useTicketStore();
  const [text, setText] = useState("");
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const validInput = validateInput();
    setValid(validInput);
  }, [text]);

  if (!action || !attribute) {
    return null;
  }

  const validateInput = () => {
    // Check if the input is required.
    if (attribute.required) {
      if (!text || text.length === 0) {
        setValid(false);
        return false;
      }
    }

    // Check if we have a validation regex.
    if (attribute.validation && attribute.validation.length > 0) {
      const regex = new RegExp(attribute.validation);
      if (!regex.test(text)) {
        return false;
      }
    }

    // Check if we have a specific input type.
    if (attribute.type === "NUMBER") {
      const val = parseInt(text);
      const valid =
        val &&
        !isNaN(val) &&
        (!attribute.minValue || val >= attribute.minValue) &&
        (!attribute.maxValue || val <= attribute.maxValue);
      if (!valid) {
        return false;
      }
    }

    // Validate email.
    if (attribute.type === "EMAIL") {
      const valid = validateEmail(text);
      if (!valid) {
        return false;
      }
    }

    return true;
  };

  const sendInput = () => {
    const validInput = validateInput();
    setValid(validInput);
    if (!validInput) {
      return;
    }

    ticketStore.answerBotAction(
      {
        text,
        message: text,
      },
      []
    );
  };

  const getInputType = () => {
    switch (attribute.type) {
      case "EMAIL":
        return "email";
      case "NUMBER":
        return "number";
      default:
        return "text";
    }
  };

  const getMinValue = () => {
    if (action.type === "NUMBER") {
      return action.minValue;
    }

    return undefined;
  };

  const getMaxValue = () => {
    if (action.type === "NUMBER") {
      return action.maxValue;
    }

    return undefined;
  };

  return (
    <div className="bot-action-text-input">
      <div className="bot-action-text-input-label">{action.title}</div>
      <div className="bot-action-text-input-innercontainer">
        <input
          type={getInputType()}
          autoComplete={attribute.type === "EMAIL" ? "email" : undefined}
          placeholder={attribute.placeholder ?? ""}
          value={attribute.value}
          min={getMinValue()}
          max={getMaxValue()}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              sendInput();
            }
          }}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <button
          className={`${!valid && "button--disabled"}`}
          onClick={() => {
            sendInput();
          }}
          type="button"
        >
          <ArrowRight />
        </button>
      </div>
    </div>
  );
}

export default BotActionInputText;
