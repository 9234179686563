import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as CheckIcon } from "../../Assets/check-solid.svg";
import { useTicketStore } from "../../Stores/Ticket";
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import {
  getCharacter,
  getIndex,
} from "../MultipleChoiceMultiSelectFormItem/MultipleChoiceMultiSelectFormItem";
import "./BotActionInputMultiselect.scss";
import { useConfigStore } from "../../Stores/Config";

function BotActionInputMultiselect({ action }: { action: any }) {
  const attribute = action?.attribute;
  const configStore = useConfigStore();
  const ticketStore = useTicketStore();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const selectedOptionsRef = useRef(selectedOptions);
  selectedOptionsRef.current = selectedOptions;
  const options = attribute?.options ?? [];

  const sendInput = () => {
    if (!selectedOptions) {
      return;
    }

    ticketStore.answerBotAction(
      {
        text: selectedOptions,
        message: selectedOptions
          ?.map((option) => {
            return options.find((o: any) => o?.value === option)?.label ?? "--";
          })
          .join(", "),
      },
      []
    );
  };

  const handleUserKeyPress = useCallback((event: any) => {
    const { key, target } = event;
    if (key && target.tagName !== "INPUT" && target.tagName !== "TEXTAREA") {
      const index = getIndex(key);

      if (index >= 0 && index < options.length) {
        const choiceValue = options[index];

        handleFormDataChange(choiceValue?.value);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handleFormDataChange = (choiceValue: any) => {
    let updatedFormDataValue = [...selectedOptionsRef.current];

    if (updatedFormDataValue.find((item: any) => item === choiceValue)) {
      updatedFormDataValue = updatedFormDataValue.filter(
        (val) => val !== choiceValue
      );
    } else {
      updatedFormDataValue.push(choiceValue);
    }

    setSelectedOptions(updatedFormDataValue);
  };

  const renderOption = (option: any, index: number) => {
    if (!option || !option.value || !option.label) {
      return null;
    }

    return (
      <div
        key={index}
        className={`multiple-choice-form-item-choice ${
          selectedOptions.includes(option?.value) &&
          "multiple-choice-form-item-choice--selected"
        }`}
        onClick={() => {
          handleFormDataChange(option?.value);
        }}
      >
        <div className="multiple-choice-form-item-choice-shortcut">
          {getCharacter(index)}
        </div>
        <div className="multiple-choice-form-item-choice-value">
          {option?.label ?? "--"}
        </div>
        <div className="multiple-choice-form-item-choice-check">
          <CheckIcon />
        </div>
      </div>
    );
  };

  if (!action || !attribute) {
    return null;
  }

  const valid = attribute.required ? selectedOptions.length > 0 : true;

  return (
    <div className="bot-action-text-multidropdown">
      <div className="bot-action-text-multidropdown-label">{action.title}</div>
      <div className="bot-action-text-multidropdown-innercontainer">
        {options.map((choice: any, index: number) =>
          renderOption(choice, index)
        )}
        <button
          onClick={() => {
            sendInput();
          }}
          type="button"
          className={`form-send-button ${
            !valid && "form-send-button--disabled"
          }`}
        >
          {configStore.translateText("next")}
          <ArrowRight />
        </button>
      </div>
    </div>
  );
}

export default BotActionInputMultiselect;
