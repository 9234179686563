export const resizeFile = async (
  file: File,
  answerBotIsActive: boolean
): Promise<File | string | undefined> => {
  if (!file) {
    return;
  }

  if (!answerBotIsActive || !file?.type?.startsWith("image")) {
    return file;
  }

  return new Promise<File | string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const maxWidth = 1800;
        const maxHeight = 768;
        let { width, height } = img;

        // Resize logic
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        // Create canvas
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("Canvas context not available"));
          return;
        }

        // Draw resized image
        ctx.drawImage(img, 0, 0, width, height);

        // Get resized image as data URL
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Failed to create blob from canvas"));
              return;
            }

            // Create a new File object from the blob
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });

            resolve(resizedFile); // Return the resized file
          },
          file.type,
          0.9 // Adjust image quality if needed
        );
      };

      if (e.target?.result) {
        img.src = e.target.result as string;
      } else {
        reject(new Error("FileReader failed to load the file"));
      }
    };

    reader.onerror = () => {
      reject(new Error("Error reading file"));
    };

    reader.readAsDataURL(file);
  });
};
