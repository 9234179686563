import { ReactComponent as CaretDown } from "../../Assets/caret-down-solid.svg";
import { useConfigStore } from "../../Stores/Config";
import { useTicketStore } from "../../Stores/Ticket";
import "./BotActionInputDropdown.scss";

function BotActionInputDropdown({ action }: { action: any }) {
  const attribute = action?.attribute;
  const configStore = useConfigStore();
  const ticketStore = useTicketStore();

  if (!action || !attribute) {
    return null;
  }

  const sendInput = (option: any) => {
    if (!option) {
      return;
    }

    ticketStore.answerBotAction(
      {
        text: option.value,
        message: option.label,
      },
      []
    );
  };

  return (
    <div className="bot-action-text-dropdown">
      <div className="bot-action-text-dropdown-label">{action.title}</div>
      <div className="bot-action-text-dropdown-innercontainer">
        <select
          onChange={(event) => {
            const val = event.target.value;
            if (val && val.length > 0) {
              const option = attribute?.options?.find(
                (option: any) => option.value === val
              );
              if (option) {
                sendInput(option);
              }
            }
          }}
        >
          <option value={""}>
            {configStore.translateText("chooseOption")}
          </option>
          {attribute.options?.map((option: any) => {
            return <option value={option.value}>{option.label}</option>;
          })}
        </select>
        <CaretDown className="caret" />
      </div>
    </div>
  );
}

export default BotActionInputDropdown;
