import { useTicketStore } from "../../Stores/Ticket";
import { ratingOptions } from "../RatingFormItem/RatingFormItem";
import "./BotActionRatingInput.scss";

function BotActionRatingInput({ action }: { action: any }) {
  const ticketStore = useTicketStore();
  if (!action) {
    return null;
  }

  const renderOption = (value: any, index: number) => {
    const onItemClick = () => {
      ticketStore.answerBotAction(
        {
          rating: value,
          message: ratingOptions[index],
        },
        []
      );
    };

    return (
      <div
        key={index}
        className={`bot-action-rating-option`}
        onClick={onItemClick}
      >
        {ratingOptions[index]}
      </div>
    );
  };

  return (
    <div className="bot-action-rating">
      <div className="bot-action-rating-label">{action.title}</div>
      <div className="bot-action-rating-innercontainer">
        <div className="bot-action-rating-items">
          {[2, 4, 6, 8, 10].map((value: any, index: number) =>
            renderOption(value, index)
          )}
        </div>
      </div>
    </div>
  );
}

export default BotActionRatingInput;
